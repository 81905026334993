var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "page-title",
        [
          _c(
            "template",
            { slot: "btn-inner" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        name: "systemManagementRole",
                        query: { activeName: _vm.$route.query.tableActiveName }
                      })
                    }
                  }
                },
                [_vm._v(" 返回 ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c("div", { staticClass: "partition-area" }, [
        _c("div", { staticClass: "tab-main" }, [
          _c("div", { staticClass: "function-accredit-header" }, [
            _vm._v(" " + _vm._s(_vm.$route.query.roleName) + "-授权设置 ")
          ]),
          _c(
            "div",
            { staticClass: "function-main" },
            [
              _c("div", { staticClass: "tree-header" }, [
                _c("span", { staticClass: "tree-left" }, [
                  _c("span", { staticClass: "tree-span-name" }, [
                    _vm._v("菜单")
                  ])
                ]),
                _c("span", { staticClass: "tree-right" }, [
                  _c("span", { staticClass: "tree-span-check" }, [
                    _vm._v("选择")
                  ]),
                  _c("span", { staticClass: "tree-span-assign" }, [
                    _vm._v("审核")
                  ])
                ])
              ]),
              _c("el-tree", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading.tab,
                    expression: "loading.tab"
                  }
                ],
                ref: "funcTree",
                attrs: {
                  data: _vm.funcList,
                  "node-key": "featureCode",
                  "default-expand-all": "",
                  "expand-on-click-node": false,
                  "default-checked-keys": _vm.selectFuncList,
                  props: _vm.defaultProps
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var node = ref.node
                      var data = ref.data
                      return _c("span", { staticClass: "custom-tree-node" }, [
                        _c("span", { staticClass: "tree-left" }, [
                          _c("span", { staticClass: "tree-span-name" }, [
                            _c("span", { staticClass: "tree-span-name-text" }, [
                              _vm._v(_vm._s(data.menu.menuName)),
                              data.menu.menuStatus === "10"
                                ? _c("span", { staticClass: "danger" }, [
                                    _vm._v("【已禁用】")
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ]),
                        _c("span", { staticClass: "tree-right" }, [
                          _c(
                            "span",
                            { staticClass: "tree-span-check" },
                            [
                              _c("el-checkbox", {
                                on: {
                                  change: function(value) {
                                    return _vm.changeCheck(value, "check", data)
                                  }
                                },
                                model: {
                                  value: data.check,
                                  callback: function($$v) {
                                    _vm.$set(data, "check", $$v)
                                  },
                                  expression: "data.check"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "tree-span-assign" },
                            [
                              _c("el-checkbox", {
                                on: {
                                  change: function(value) {
                                    return _vm.changeCheck(
                                      value,
                                      "assign",
                                      data
                                    )
                                  }
                                },
                                model: {
                                  value: data.assign,
                                  callback: function($$v) {
                                    _vm.$set(data, "assign", $$v)
                                  },
                                  expression: "data.assign"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }