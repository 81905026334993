<!-- list -->
<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <el-button type="primary" @click="save()">
          保存
        </el-button>
        <el-button @click="$router.push({ name: 'systemManagementRole', query: { activeName: $route.query.tableActiveName } })">
          返回
        </el-button>
      </template>
    </page-title>
    <div class="partition-area">
      <div class="tab-main">
        <div class="function-accredit-header">
          {{ $route.query.roleName }}-授权设置
        </div>
        <!--      <div class="accredit-header">角色：{{roleName}}</div>-->
        <div class="function-main">
          <div class="tree-header">
            <span class="tree-left">
              <span class="tree-span-name">菜单</span>
            </span>
            <span class="tree-right">
              <span class="tree-span-check">选择</span>
              <span class="tree-span-assign">审核</span>
            </span>
          </div>
          <el-tree
            ref="funcTree"
            v-loading="loading.tab"
            :data="funcList"
            node-key="featureCode"
            default-expand-all
            :expand-on-click-node="false"
            :default-checked-keys="selectFuncList"
            :props="defaultProps"
          >
            <span slot-scope="{ node, data }" class="custom-tree-node">
              <span class="tree-left">
                <span class="tree-span-name">
                  <span class="tree-span-name-text">{{ data.menu.menuName }}<span v-if="data.menu.menuStatus === '10'" class="danger">【已禁用】</span>
                  </span>
                </span>
              </span>
              <span class="tree-right">
                <span class="tree-span-check">
                  <el-checkbox v-model="data.check" @change="value =>changeCheck(value, 'check', data)" />
                </span>
                <span class="tree-span-assign">
                  <el-checkbox v-model="data.assign" @change="value =>changeCheck(value, 'assign', data)" />
                </span>
              </span>
            </span>
            <!--                    <span class="custom-tree-node" slot-scope="{ node, data }">-->
            <!--                      <span class="function-tree">{{data.menu.menuName}}<span class="danger" v-if="data.menu.menuStatus === '10'">【已禁用】</span></span>-->
            <!--                    </span>-->
          </el-tree>
        </div>
      </div>
    </div>
  </el-main>
</template>
<script>
export default {
  components: {
  },
  data () {
    return {
      appKey: this.$route.query.appKey,
      roleName: this.$route.query.roleName,
      roleCode: this.$route.query.roleCode,
      roleMenuAssign: true,
      list: [],
      data: [],
      funcList: [],
      selectFuncList: [],
      defaultProps: {
        children: 'childList'
      },
      activeName: '',
      loading: {
        menu: false,
        submit: false,
        tab: false,
        form: false
      },
      dialog: {
        menu: false
      }
    }
  },
  watch: {},
  created () {
    this.findAuth()
    this.getAllList()
  },
  methods: {
    findAuth () {
      const systemFeature = this._.find(this.$store.state.featureList, { code: 'role-platform' })
      const roleMenuAssign = this._.find(this.$store.state.featureList, { code: 'role-menu-assign' })
      if (systemFeature) {
        this.apiGroup = 'role'
      } else {
        this.apiGroup = 'roleCustomer'
      }
      if (roleMenuAssign) {
        this.roleMenuAssign = true
      }
    },
    // 拉取所有菜单
    getAllList () {
      this.loading.tab = true
      const appKey = this.appKey
      const roleCode = this.roleCode
      Promise.all([this.api.system.menu.menuList({ appKey: this.appKey }), this.api.system.role.featureList(this.appKey, this.roleCode)]).then(res => {
        const funcList = res[0]
        const selectFuncList = res[1]
        this.funcList = []
        // 循环第一级菜单
        funcList.data.data.forEach((val, index, arr) => {
          val.check = false
          val.edit = false
          val.assign = false
          let level2 = val
          let lv2CheckTotal = 0
          let lv2EditTotal = 0
          let lv2AssignTotal = 0
          if (level2.childList.length > 0) {
            level2.childList.forEach((val, index, arr) => {
              val.parentId = level2.menu.id
              val.check = false
              val.edit = false
              val.assign = false
              let level3 = val
              let lv3CheckTotal = 0
              let lv3EditTotal = 0
              let lv3AssignTotal = 0
              if (level3.featureList.length > 0) {
                level3.featureList.forEach((val, index, arr) => {
                  val.parentId = level3.menu.id
                  // console.log(val, '第三级')
                  val.check = false
                  val.edit = false
                  val.assign = false
                  let isCheck = this._.find(selectFuncList.data.data, {featureCode: val.featureCode})
                  if (isCheck) {
                    lv3CheckTotal += 1
                    val.check = true
                    if (isCheck.editMenuFeature === 0) {
                      val.edit = false
                    } else {
                      val.edit = true
                      lv3EditTotal += 1
                    }
                    if (isCheck.assignMenuFeature === 0) {
                      val.assign = false
                    } else {
                      val.assign = true
                      lv3AssignTotal += 1
                    }
                  } else {
                    val.check = false
                    val.edit = false
                    val.assign = false
                  }
                  level3.featureList[index].menu = level3.featureList[index]
                  level3.featureList[index].menu.menuCode = level3.featureList[index].menu.featureCode
                  level3.featureList[index].menu.menuName = level3.featureList[index].menu.featureName
                  level3.featureList[index].menu.menuStatus = level3.featureList[index].menu.featureStatus
                  level3.featureList[index].menuCode = level3.menu.menuCode
                })
                if (lv3CheckTotal === level3.featureList.length) {
                  val.check = true
                  lv2CheckTotal += 1
                }
                if (lv3EditTotal === level3.featureList.length) {
                  val.edit = true
                  lv2EditTotal += 1
                }
                if (lv3AssignTotal === level3.featureList.length) {
                  val.assign = true
                  lv2AssignTotal += 1
                }
              }
              level3.childList = level3.featureList
            })
            if (lv2CheckTotal === level2.childList.length) {
              val.check = true
            }
            if (lv2EditTotal === level2.childList.length) {
              val.edit = true
            }
            if (lv2AssignTotal === level2.childList.length) {
              val.assign = true
            }
          }
        })
        this.funcList = funcList.data.data
        // console.log(this.funcList)
        // selectFuncList.data.data.forEach((val, index, arr) => {
        //   this.selectFuncList.push(val.featureCode)
        // })
        this.selectFuncList = selectFuncList.data.data
        // console.log(selectFuncList.data.data, '选中')
        this.loading.tab = false
      }).catch(result => {
        // console.log(result)
        this.loading.tab = false
      })
    },
    // 保存授权的菜单
    save () {
      // this.loading.submit = true
      // console.log(selectFunc)
      let data = []
      let featureList = []
      this.funcList.forEach(lv1 => {
        lv1.childList.forEach(lv2 => {
          lv2.featureList.forEach(item => {
            featureList.push(item)
            if (item.check) {
              let auth = {
                menuCode: item.menuCode,
                featureCode: item.featureCode
              }
              if (this.roleMenuAssign) {
                auth.assignMenuFeature = item.assign === false ? 0 : 1
              }
              data.push(auth)
            }
          })
        })
      })
      if (featureList.filter(item => item.assign === true && item.check === false).length > 0) {
        this.$message.error('必须选择功能,才能选择审核')
        return false
      }
      // selectFunc.forEach((val, index, arr) => {
      //   if (val.menuCode) {
      //     let menu = {}
      //     menu.menuCode = val.menuCode
      //     menu.featureCode = val.featureCode
      //     data.push(menu)
      //   }
      // })
      this.api.system.role.featureSet(this.appKey, this.roleCode, data).then(result => {
        this.$message.success(result.data.data)
        this.findAuth()
        this.getAllList()
        this.loading.submit = false
      }).catch(e => {
        // console.log(result)
        this.loading.submit = false
      })
    },
    changeCheck (value, type, data) {
      // console.log(value, type, data)
      if (value === false) {
        if (data.childList && data.childList.length > 0) {
          data.childList.forEach(item => {
            item[type] = value
            if (item.featureList && item.featureList.length > 0) {
              item.featureList.forEach(feature => {
                feature[type] = value
              })
            }
          })
        }
        if (data.parentId) {
          this.funcList.forEach(item => {
            if (item.menu && item.menu.id === data.parentId) {
              item[type] = value
            } else {
              if (item.childList && item.childList.length > 0) {
                item.childList.forEach(node => {
                  if (node.menu && node.menu.id === data.parentId) {
                    item[type] = value
                    node[type] = value
                  }
                })
              }
            }
          })
        } else {
        }
      } else {
        // console.log(data)
        if (data.childList && data.childList.length > 0) {
          data.childList.forEach(item => {
            item[type] = value
            if (item.featureList && item.featureList.length > 0) {
              item.featureList.forEach(feature => {
                feature[type] = value
              })
            }
          })
        }
        if (data.parentId) {
          this.funcList.forEach(lv1 => {
            let lv2CheckTotal = 0
            let lv2EditTotal = 0
            let lv2AssignTotal = 0
            if (lv1.childList && lv1.childList.length > 0) {
              lv1.childList.forEach(lv2 => {
                if (lv2.featureList && lv2.featureList.length > 0) {
                  let lv3CheckTotal = 0
                  let lv3EditTotal = 0
                  let lv3AssignTotal = 0
                  lv2.featureList.forEach(lv3 => {
                    if (lv3.check) {
                      lv3CheckTotal += 1
                    }
                    if (lv3.edit) {
                      lv3EditTotal += 1
                    }
                    if (lv3.assign) {
                      lv3AssignTotal += 1
                    }
                  })
                  lv2.check = (lv3CheckTotal === lv2.featureList.length)
                  lv2.edit = (lv3EditTotal === lv2.featureList.length)
                  lv2.assign = (lv3AssignTotal === lv2.featureList.length)
                }
                if (lv2.check) {
                  lv2CheckTotal += 1
                }
                if (lv2.edit) {
                  lv2EditTotal += 1
                }
                if (lv2.assign) {
                  lv2AssignTotal += 1
                }
              })
              lv1.check = (lv2CheckTotal === lv1.childList.length)
              lv1.edit = (lv2EditTotal === lv1.childList.length)
              lv1.assign = (lv2AssignTotal === lv1.childList.length)
            }
          })
        }
      }
    }
  }
}
</script>
<style></style>
